<template>
  <div style="padding: 20px; box-sizing: border-box; width: 100%">
    <div class="flex justify-between">
      <p class="font-bold text-lg" style="margin-left: 10px">Message</p>
      <el-button size="small" type="primary" @click="clickShowSendMessage"
        >Send Message
      </el-button>
    </div>
    <div style="margin-top: 20px">
      <div
        v-for="(message, index) in dataList"
        class="message-item"
        :key="index"
      >
        <div style="width: 100%; text-align: right">
          <p class="message-time">
            {{ formatMessageTime(message.create_time) }}
          </p>
        </div>
        <p class="message-content" style="margin-top: 10px">
          {{ message.message_content }}
        </p>
        <div class="message-reply">
          <div
            v-if="message.reply_info"
            style="display: flex; flex-direction: row"
          >
            <p class="reply-title">Reply</p>
            <p class="reply-content">
              {{ message.reply_info.message_content }}
            </p>
          </div>
          <p v-else class="reply-content" style="padding-left: 0px">
            No reply yet
          </p>
        </div>
      </div>
    </div>
    <el-empty description="No Data Yet" v-if="dataList.length === 0"></el-empty>
    <el-dialog
      :visible.sync="showSendMessageDialog"
      title="Send Message"
      width="45%"
    >
      <div>
        <el-input
          v-model="messageInfo.content"
          type="textarea"
          :rows="10"
          placeholder="Please input"
        ></el-input>

        <div style="width: 100%; text-align: center; margin-top: 30px">
          <el-button type="primary" size="medium" @click="submitMessage"
            >Submit Message</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getMessageList, sendMessage } from "../../api/eae";
import { getUserId } from "../../utils/store";
import { formatDate } from "../../utils/date";

export default {
  name: "index",
  data() {
    return {
      dataList: [],
      showSendMessageDialog: false,
      messageInfo: {
        student_id: getUserId(),
        content: "",
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getMessageList(getUserId()).then((res) => {
        this.dataList = res.data.data;
      });
    },
    formatMessageTime(time) {
      return formatDate(new Date(time * 1000), "yyyy.MM.dd");
    },
    submitMessage() {
      if (this.messageInfo.content == "") {
        this.$message.warning("Please input message");
        return;
      }
      sendMessage(this.messageInfo.student_id, this.messageInfo.content).then(
        (res) => {
          this.$message.success("Submit message success~");
          this.showSendMessageDialog = false;
          this.fetchData();
        }
      );
    },
    clickShowSendMessage() {
      this.messageInfo.content = "";
      this.showSendMessageDialog = true;
    },
  },
};
</script>

<style scoped lang="less">
.message-item {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(255, 255, 255, 0.55);
  border-radius: 10px;
  padding: 18px 30px;
  box-sizing: border-box;
  margin-bottom: 18px;
}

.message-title {
  font-size: 24px;
  font-family: Century Gothic-Bold, Century Gothic;
  font-weight: bold;
  color: #ff6450;
}

.message-content {
  font-size: 15px;
  color: #666666;
}

.message-time {
  font-size: 15px;
  font-family: Century Gothic-Regular, Century Gothic;
  font-weight: 400;
  color: #949494;
}

.message-reply {
  margin-top: 18px;
  padding: 15px 18px;
  box-sizing: border-box;
  width: 100%;
  background: #f4f9ff;
  border-radius: 10px;
  display: flex;
  align-items: center;

  .reply-title {
    font-size: 18px;
    font-family: Century Gothic-Bold, Century Gothic;
    font-weight: bold;
    color: #579eff;
  }

  .reply-content {
    flex: 1;
    padding-left: 18px;
    box-sizing: border-box;
    font-size: 15px;
    font-family: Century Gothic-Bold, Century Gothic;
    color: #666666;
  }
}
</style>
